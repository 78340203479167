@import "../../../../../../resources/styles/media.less";
@import "../../../../../../resources/styles/colors.less";

.container {
    margin-bottom: 2rem;
}

.sectionHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    background: @secondaryBackground;
    padding: 0.725rem 2rem;
    min-height: 60px;
    margin-bottom: 0.5rem;
}

.title {
    margin: 0;
}

.contentContainer {
    padding: 0.5rem 2rem;
}

.nothingToShow {
    color: @secondaryText;
    font-size: 0.875rem;
}