@import "../../resources/styles/variables";

.key {
    font-weight: @bold;
}

.row {
    padding-bottom: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}