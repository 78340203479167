@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";

.lifecycle-map {
  position: relative;
  overflow: hidden;
  color: @primaryText;
}

.lifecycle-map > ul:before { //vertical line joining all phases
  content: "";
  position: absolute;
  top: 20/16rem;
  bottom: 24/16rem;
  left: 6/16rem;
  width: 1/16rem;
  background: @secondaryText;
}

.lifecycle-map li {
  position: relative;
  list-style-type: none;
  margin: 8/16rem 4/16rem 8/16rem 0;
  font-size: 14/16rem;
  font-weight: @normal;
  line-height: 1.36rem;
  text-align: left;
}

.lifecycle-map > ul > li.phase .phase-name {
  font-weight: @semibold;
}

.lifecycle-map li.phase ul {
  list-style-type: none;
  margin-left: 24/16rem;
  padding-left: 0;
}

.lifecycle-map li.phase li {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.lifecycle-map li.target:before { //horizontal line from child environment icon
  content: "";
  position: absolute;
  top: 8/16rem;
  left: -18/16rem;
  width: 18/16rem;
  height: 1/16rem;
  background: @secondaryText;
}

.lifecycle-map li.phase {
  padding-left: 0;
}






