@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.headerColumn {
    width: 25%;
    padding: 0;
}

.emptyCell {
    display: flex;
    flex-direction: row;
    font-weight:400;
    font-size: 0.875rem;
}

.filterControl {
    width: 240/16rem!important; // Override MUI.
}

.runBy {
    font-size: 0.875rem;
    color: @secondaryText;
    margin: 0.25rem 0; // To line up with the margin from the chips.
}

.customListItem {
    font-size: 0.9375rem; // To match the font size of the task cell name.
    line-height: 1.18rem;
    color: @primary;
    margin: 0.25rem 0;
    font-weight: @normal;
}

.resultsTableRow {
    border-top: unset!important;
}
.resultsTableHeaderRow {
    border-top: unset!important;
    border-bottom: unset!important;
}

.nextScheduledRow {
    margin-left: 2rem;
}