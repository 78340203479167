@import "../../../resources/styles/colors";

.breadcrumb {
  font-size: 0.875rem; /* mark.siedle: This matches the dataTableColumnHeader, so this has a consistent look. */
  line-height: 1rem;
  color: @secondaryText;
  .breadcrumbLink {
    color: @primaryText;
    text-decoration: none;
    &>:active, &>:visited{
        color: @primaryText;
    }
    &:hover{
        color: @mainText;
    }
  }
}