@import "../../../../resources/styles/media";

.permissions {
  list-style-type: disc;
  margin-left: 2rem;
}

.roleName {
  margin-top: 0rem;
}

.user {
  display: flex;
  align-items: center;
}

.userName {
  margin-left: 0.5rem;
}

.nameContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .lockedCheck {
    padding-right: 0.5rem;
  }
}

.permissionColumn {
  width: 30%;
  @media (max-width: @screen-sm) {
    width: auto;
  }
}
.descriptionColumn {
  width: auto;
}