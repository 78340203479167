@import "../resources/styles/colors";
@import "../resources/styles/variables";
@import "../resources/styles/media";

.root {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}
.contentContainer {
    display: flex;
    height: 100%;
}
.appBar {
    position: relative;
}
.hide {
    display: none;
}
.searchIconButton {
    font-size: 1.2rem;
}
.searchControls {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
    cursor: pointer;
}
.searchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative; // Needed to contain our closeIcon
}
.clearTextAction {
    height: 16px !important; // Override MUI. This is needed otherwise the clear button pushes the height of the input.
}
.popper {
    z-index: 1201; // Override sticky layout and the help sidebar.
}
