@import "../../../resources/styles/colors";

.container {
    display: flex;
    flex-direction: column;
    text-align: center;
    & > * {
        margin: 1.5rem 0 0 0;
    }
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: @paper1;
    width: 23.5rem;
    text-align: center;
    border-radius: 0.25rem;
    overflow: hidden; // To support border-radius and our horizontal loading indicator.
    box-shadow: @shadowSmall;
}

.description {
    padding: 0 1.5rem 1.5rem 1.5rem;
    width: 100%; // ie11
}

.back {
    padding-top: 1.5rem;
}
.logo {
    background-color: @cyanConstant;
    color: @whiteConstant;
    font-size:2rem;
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}