@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";
@import "../../../resources/styles/media";

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.select {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto; // needed by ie11;
}

.summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 100%; // needed by ie11;
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%; // needed by ie11;
    &:nth-last-child(1) {
        margin-top: 0.5rem;
    }
}

.titleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.title {
    width: 100%;
}

.details {
    display: flex;
    flex-direction: row;
}

.icon {
    padding: 0 0 0 0.4rem;
    align-self: center;
}

.propertyContainer {
    padding: 0.4rem 0.4rem 0 0;
    display: flex;
    color: @primaryText;
    font-size: 0.875rem;

    span:nth-child(2) {
        margin-left: 0.25rem;
        margin-right: 0.75rem;
    }
    + .thumbprint {
        font-weight: @normal;
    }
}
