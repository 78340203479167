@import "../../../resources/styles/colors";

// Replace this CSS with using MUI Theming
.dragDropFile {
  > div:first-child {
    min-height: 10rem;
    margin-bottom: 1rem;
    p, svg {
      color: @secondaryText;
      font-size: 1rem;
    }
  }
}