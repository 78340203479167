@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.box {
    padding: 0.5rem;
    align-self: stretch;
    font-weight: @normal;
    margin-right: 0.5rem;
}

.wrapper {
    margin-top: 2rem;
}

.notRun {
    margin-right: 0.5rem;
}

.taskSucceeded {
    .box;
    color: @successText;
    background-color: @successHighlight;
    .title {
        color: @successText;
    }
}

.taskFailed {
    .box;
    color: @dangerText;
    background-color: @dangerHighlight;
    .title {
        color: @dangerText;
    }
}

.taskTime {
    margin-right: 0.5rem;
}
