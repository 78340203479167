@import "../../../../resources/styles/colors.less";

.user {
    display: flex;
    align-items: center;
}

.userName {
    margin-left: 0.5rem;
}

.groupImage {
    width: 1.5rem;
    height: 1.5rem;
    background-color: @logoBackground;
    border-radius: 50%;
}
