@import "../../../resources/styles/colors.less";

.container {
  display: flex;
  align-items: center;
}

.separator {
  margin: 0 2/16rem;
}

.ampm {
  margin-left: 3/16rem;
}
.input {
  background-color: @paper1 !important;
 + b, ~ b {
    background-color: @pagingButtonBackground !important;
    border-style: none !important;
    &:hover {
      background-color: @pagingButtonBackgroundHover !important;
      > i, ~i {
        background-color: @whiteConstant !important;
      }
    }
    > i, ~ i {
      background-color: @secondaryText !important;
    }
  }
}