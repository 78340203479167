.timePicker {
    display: flex;
    align-items: center;
    .digit {
      width: 2rem;
      margin-right: 0.25rem;
    }
    .digit:first-of-type {
      margin-right: 1rem;
    }

    // remove spinners
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance:textfield;
    }
    input {
      text-align: center;
    }
  }