@import "../../../resources/styles/variables";
@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.popper {
    z-index: 9999;
}

.paper {
    background: @contextualHelpBackground;
    box-shadow: @shadowMedium;
    border-radius: @radiusLg;
    padding: 24px;
    font-size: 14px;
    max-width: 328px;

    p {
        margin-top: 0;
        margin-bottom: 16px;
    }

    p:last-child {
        margin-bottom: 0;
    }

    &.medium {
        max-width: 500px;
    }
}

.paper::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid @contextualHelpBackground;
    border-color: @contextualHelpBackground @contextualHelpBackground transparent transparent;
    background: @contextualHelpBackground;
    transform-origin: 0 0;
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
}

@media (max-width: @screen-md) {
    .paper::after {
        display: none;
    }
}

.bottom-start::after {
    top: 0px;
    left: 0;
    margin-left: 63px;
    transform: rotate(135deg);
}

.bottom-end::after {
    top: 0px;
    left: 100%;
    margin-left: -33px;
    transform: rotate(135deg);
}

.top-end::after {
    bottom: -20px;
    left: 100%;
    margin-left: -60px;
    transform: rotate(-45deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
}

.top-start::after {
    bottom: -20px;
    left: 0;
    margin-left: 33px;
    transform: rotate(-45deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
}

.left-start::after {
    top: 3px;
    right: -20px;
    margin-top: 60px;
    transform: rotate(-135deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
}

.left-end::after {
    bottom: 13px;
    right: -20px;
    transform: rotate(-135deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
}

.right-start::after {
    top: 0px;
    left: 2px;
    margin-top: 30px;
    transform: rotate(45deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
}

.right-end::after {
    bottom: 43px;
    left: 2px;
    transform: rotate(45deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
}

.globalContainer {
    display: inline-block;
}

.absolute {
    position: relative;
    z-index: 9; // If this is >=10 then it sits on top of process editor heading when scrolled
}

.iconContainer {
    cursor: pointer;
    margin: 3px;
    outline: 2px solid transparent;

    &.withOutline {
        &:focus {
            outline: 2px solid @focusIconButton;
            border-radius: @radiusCircle;
            margin: 3px;
            padding: 0;
        }
    }
}

.circle {
    background-color: #C5E6FF;
    border-radius: 100px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoIcon {
    color: #145586;
    height: 12px;
    font-size: 12px;
}

.label {
    cursor: pointer;
}

.label * {
    cursor: pointer;
}
