.container {
    display: flex;
    width: 100%;
    //flex-wrap: wrap; // Needed for responsive design, since <select> element goes 100%.
    align-items: flex-end;
}

.outerContainer{
    width:100%;
}

.innerContainer {
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
    margin-left: 0.5rem;
}

.select {
    flex: 1 1 auto;
}