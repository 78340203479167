
.showAdvancedButton{
    alignSelf: "flex-start";
    margin: 0.5rem 0 0.5rem 0;
}

.container > * {
    margin: 0 0 1rem 0;
}

.codeEditorContainer {
    // there are currently 12 lines of text (1.25rem is the line height) and roughly 3rem of padding/margins/scrollbars
    min-height: calc((12 * 1.25rem) + 3rem);
}