@import "../../resources/styles/colors.less";

.icon:global(.MuiSvgIcon-root) {
  width: 14px;
  height: 14px;
  margin: 5px;
  fill: @chipIcon;
}

.avatar:global(.MuiAvatar-root) {
  width: 24px;
  height: 24px;
  background-color: @avatarBackground;
}