@import "../../../resources/styles/colors.less";

.cellIcon {
    opacity: 0.5;

    color: @secondaryText;
}

.highlightedCellIcon {
    &:hover {
        opacity: 1;
    };
    .cellIcon;
}

// ensures the tooltip container doesn't add extra size to the icon, and cause it to not be centered
.iconSizing {
    height: 1.5rem;
    width: 1.5rem;
}

.canClick {
    cursor: pointer;
}

// The icon should be in a cell with fixed dimensions, so height and width 100% should be safe.
.iconContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1rem;
}