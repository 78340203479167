@import "../../../../../resources/styles/media";

.row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
}

.inputs {
  flex: 1;
  min-width: 255px;
}
