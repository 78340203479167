@import "../../../../../resources/styles/variables.less";
@import "../../../../../resources/styles/colors.less";
@import "../../../../../resources/styles/media";

.centered {
    display: flex;
    justify-content: center;
}

.outerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  .centered();
}

.active {
    background-color: @categoryButtonBackground !important;
}

.cardGroup {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 2rem;
    justify-content: center;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap; // Need this to wrap for mobile.
    margin-bottom:1.5rem;
}

.headingContainer {
    margin: 0; //To override the Section margin.
    text-align: center;
}

.paperActions{
    padding: 0.5rem 0 0 0;
}

.actions > * {
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important; // margin at lower resoluations.
}

.link{
    cursor:pointer;
}
.logo {
    display: none;
}

.groupHelp {
    margin: 0 1rem;
    display: flex;
    justify-content: center;
}

.groupHeading {
    text-align: center;
    padding: 8/16rem 18/16rem;
    font-size: 17/16rem;
}

.contentContainer {
    min-height: 33rem; // So our options don't jump around.
}

.instructionsIntro {
    margin-bottom: 0.5rem;
}