@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/fonts";

.body{
  padding-right: 1rem;
}
.filters {
  display: flex;
  justify-content: flex-end;
  >div {
    width: 10rem;
  }
}