
.headerColumn {
  max-width: none !important;
}

.headerColumn:first-child {
  width: 300/16rem;
}

.headerColumn:nth-child(2) {
  width: 150/16rem;
}

.rowColumn {
  width: auto !important;
  max-width: none !important;
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
}