@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.healthStatusIconContainer {
  background: @logoBackground;
  border-radius: 30px;
  margin-right: 0.5rem;
  border: 0.0625rem solid @logoBackground;
  img, svg {
    display: flex;
    min-width: 1rem;
  }
}