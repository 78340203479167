@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";

.menuItem {
  display: flex;
}

.content {
  flex-grow: 1;
}
