@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";
@import "../../../resources/styles/media";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.select {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto; // needed by ie11;
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 100%; // needed by ie11;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%; // needed by ie11;
  &:nth-last-child(1) {
    margin-top: 0.5rem;
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title {
  width: 100%;
}

.details{
  display: flex;
  flex-direction: row;
}