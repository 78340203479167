@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.description {
    margin-bottom: 0.25rem;
}

.empty {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5rem;
}

.grid {
    display: flex;
    flex-direction: column;
    .row {
        display: flex;
        flex-direction: row;
        margin: 0.3rem 0;
        .header {
            flex-basis: 13%;
            text-align: left;
            margin-right: 1.25rem;
            font-weight: @bold;
        }
        .value {}
    }
}

.designRuleButton {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.25rem;
}

.codeWrapper {
    position: relative;
    height: 15rem;
}

.rulesList {
    margin: 0;
    padding-left: 1rem;
    list-style-type: decimal;
    li {
        padding-bottom: 0.625rem;
        &:last-child {
            padding-bottom: 0;
        }
    }
}

.rulesEditHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addVersionActionContainer {
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
}
