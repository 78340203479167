.firstColumn {
    width: 50%;
    margin-right: 1rem;
}

.firstColumnFullWidth {
    width: 100%;
}

.secondColumn {
    width: 50%;
    margin-left: 1rem;
}

.scopeContainer {
    display: flex;
    flex-direction: row;
}