.icon {
    width: 2rem;
    height: 2rem;
    padding-right: 0.5rem;
}

.link {
    height: 100%;
    display: flex;
    align-items: center;
}

.tooltipWrapper {
    height: 100%;
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text {
    min-width: 0;  // Prevents the icon from shrinking in Edge when the text is long and is clipped
    flex-basis: 0; // Prevents the icon from shrinking in IE11 when the text is long and is clipped
}