.notVisible {
    opacity: 0;
}
.fadeIn {
    opacity: 1;
    transition: opacity 300ms ease-in;
    -moz-transition: opacity 300ms ease-in;
    -webkit-transition: opacity 300ms ease-in;
}
.container {
    height: 100%; // Needed for dialogs.
}