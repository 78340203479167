.branchList {
    margin-top: 1rem
}

.branchListItems {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.calloutContainer {
    margin: 0.75rem 0;
}