.headerColumn:first-child {
  width: 300/16rem;
}

.headerColumn:nth-child(3) {
  width: 1rem;
}

.rowColumn {
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
  padding: 0.175rem 1.125rem!important;
  vertical-align: middle!important;
}

.feedName {
  margin: 1rem 0;
}