@import "../../../resources/styles/colors.less";

.imageContainer {
    display: flex;
}

.svgIcon {
    display: flex;
    height: 100% !important;
    width: 100% !important;

    // These colours will stay constant. Use these if you always want a colour to be used in both dark and light mode.
    .imgCyan {
        fill: @imgCyan;
    }
    .imgCyan50 {
        fill: @imgCyan50;
    }
    .imgWhite {
        fill: @imgWhite;
    }
    .imgNavy {
        fill: @imgNavy;
    }

    // Use these styles to toggle between colours suitable for light or dark mode
    .imgDark {
        fill: @imgDark; // toggle from navy(Dark mode) to white(Light mode)
    }
    .imgLight {
        fill: @imgLight; // toggle from white(Light mode) to navy(Dark mode)
    }
    .imgBlueGrey {
        fill: @imgBlueGrey; // toggle from bluegray(light mode) to a tint of navy(dark mode)
    }
    .imgGrey {
        fill: @imgGrey; // toggle from grey(light mode) to a tint of navy(dark mode)
    }
    .imgGreen {
        fill: @imgGreen;
    }
    .imgDanger {
        fill: @imgDanger;
    }
    .imgLightGrey {
        fill: @imgLightGrey;
    }

    .dashDark {
        stroke: @imgDark;
        stroke-dasharray: 5;
        fill: transparent;
        animation: dash 60s linear infinite;
        -webkit-animation: dash 60s linear infinite;
        stroke-dasharray: 5;
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 1000;
        }
    }
}