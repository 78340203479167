@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";
@import "../../../../../resources/styles/variables";

.loadMore div {
    padding-left: 0;
    justify-content: left;
    margin-left: 0;
}

.filesListItem {
    padding-bottom: 0.5rem;
    font-weight: normal;
}

.filesListItem span {
    position: static;
}

.filesList {
    margin-left: 0;
    margin-top: 0;
}

.fileListItemIcon {
    padding-right: 0.25rem;
}
