@import "../../../../../../resources/styles/colors";
@import "../../../../../../resources/styles/variables.less";

.box {
    padding: 0.625rem 1rem;
    align-self: stretch;
    font-size:14/16rem;
    color: @primaryText;
}

.waiting {
    background-color: @infoBackground;
    .box;
    .title {
        color: @infoHeaderText;
        .title;
    }
}

.success {
    background-color: @successBackground;
    .box;
    .title {
        color: @successHeaderText;
        .title;
    }
}

.failure {
    background-color: @dangerBackground;
    .box;
    .title {
        color: @dangerHeaderText;
        .title;
    }
}

.none {
    .box();
}

.title {
    display: flex;
    justify-content: space-between;
    font-weight: @normal;

    + div {
        margin-top: 0.25rem;
    }
    > .note {
        margin-bottom: 0;
    }
}

.contentContainer {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 0.250rem
}

.waiting-icon {
    height: 1rem;
    width: 1rem;
    .icon
}
