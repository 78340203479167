@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.permissions {
  ul {
    list-style-type: disc;
    margin-left: 2rem;
    color: @ternaryText;
    margin-top: 8px;
  }
  h5 {
    color: @primaryText;
    margin-bottom: 0;
  }
}

.roleName {
  margin-top: 0rem;
}

.missingRoleName{
  .roleName;
  color: @dangerText;
}

.filterText {
  display: flex;
  align-items: center;
  margin: 0 2/16rem;
}

.lists {
  display: flex;
  @media (max-width: @screen-sm) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; // Need this to wrap for mobile.
  }
}

.list {
  flex: 50%;
  margin-top: 1rem;
}

.listHeader {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  h4 {
    margin: 0.5rem 0;
  }
}

.row {
  display: inline-flex;
  flex-direction: row;
  width:100%;
}

.detailsHighlight {
  padding: 1rem 0;
  background-color: @secondaryBackground;
}

.permissionDescriptionsActionContainer {
  margin: 0.25rem 0;
}