@import "../../../../resources/styles/colors";

.disabled > span > span > span > div  {
  opacity: 0.5;
  > span > div {
    color: @chipText;
  }
}
.disabled * {
    color: @disabledButtonText;

  svg {
    opacity: .5;
  }
}
