@import "../../../../resources/styles/variables";

.filterBox {
  margin-left: 1rem;
  margin-top: 2.6rem;
}

.filter {
  width: 50rem;
  align-items: flex-end;
}

.row {
  display: flex;
  flex-direction: row;
}

.logo {
  vertical-align: middle;
  justify-content: center;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
}

.linkWithCopy {
  display: inline-flex;
  flex-direction: row;
  .copyAction {
    margin: -1rem 0;
  }
}

.spaceName {
  font-weight: @semibold;
}

.addTeamInstructions {
  margin-bottom: 3/16rem;
}

.addTeamInstructionsList {
  list-style-type: decimal;
  margin: 0;
  padding-left: 25/16rem;
}

.spaceLimitHit {
  text-align: center;
}

.spaceManagersCalloutContainer {
  margin-bottom: 0.5rem;
}