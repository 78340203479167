@import "../../resources/styles/variables";
@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
}
