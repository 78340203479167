.serverUri {
    word-wrap: break-word;
    word-break: break-all;
}

.filterBoxStandardWidth {
    width: 15.6rem !important; /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */
}

.sqlMaxPoolSizeWarning {
    padding-left: 0.25rem;
}
