@import "../../../../../resources/styles/colors";

.checkbox {
  display: inline-block;
  margin-right: 2rem;
}
.optionsBar {
  display: flex;
  align-items: center;
}
.tableColumn {
  padding-bottom: 0.5rem !important;
}
.versionRadio {
  margin-top: -1rem;
}
.loadMore {
  display: flex;
  justify-content: center;
}

.infoCircle {
  color: @info;
  width: 1.5rem;
  margin-top: 1rem;
  display: flex;
}

.legend {
  margin-top: 0.5rem;

  .infoIcon {
    color: @info;
  }
}