.apiKeyEntry {
  width: 100%;
  margin-top: 0;
  font-size: 1rem;
}

.apiKeyExpiry {
  display: flex;
  flex-direction: row;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.apiKeyCreated {
  font-size: 0.875rem;
}
