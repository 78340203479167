.formExpander, .formNonExpander {
    width: 100%;
    box-shadow: none!important; //swap the box-shadow of the material-ui control for a border
    border-radius: 0!important;
    border-width: 0!important;
    margin: 0 0 -0.0625rem 0; //so we don't get double borders with consecutive expanders

    // mark.siedle - Oh God, these are hidden away inside the Card from material-ui #plsKillMe
    > div:first-child {
        padding: 0!important;
    }
}

.formExpander {
    > div:first-child {
        > div:first-child {
            cursor: pointer;
            width: 100%;
        }
    }
}

.cardMedia {
    margin: 0;
    padding: 0;
}
