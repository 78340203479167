@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";
@import "../../../../../resources/fonts/Material/Material";

h5 {
    margin-bottom: 0.25rem !important;
    font-weight: 600;
}

.icon {
    margin-right: 0.25rem;
    position: relative;
    top: 1px;
    font-family: Material Icons;
}

.overviewContainer {
    p {
        margin: 0;
    }
    font-size: 1rem;
    img {
        min-width: 200px;
        width: 50% !important;
        max-width: 400px;
        height: auto;
    }
}

.topicsContainer {
    font-size: 0.875rem;
    a em {
        font-size: 0.75rem;
    }
}

.overviewHighlight {
    color: @primaryText;
    font-size: 0.875rem;
    p {
        margin-top: 0;
    }
}

.terms {
    border-top: 1px solid @dividerLight;
    padding-top: 1rem;
    margin-top: 1rem;
    div,
    .note {
        a {
            font-weight: @semibold;
        }
    }
}

.needHelp {
    h5:before {
        content: "\e86d";
        .icon;
    }
}
.newToOctopus {
    h5:before {
        content: "\e05e"; //TODO - Jess - This isn't the best icon at the small size so will think of something else
        .icon;
    }
}
.tutorials {
    h5:before {
        content: "\e86d";
        .icon;
    }
}
.docs {
    h5:before {
        content: "\e02f";
        .icon;
    }
}
.support {
    h5:before {
        content: "\e253";
        .icon;
    }
}
.webinar {
    h5:before {
        content: "\e04b";
        .icon;
    }
}
.video {
    h5:before {
        content: "\e63a";
        .icon;
    }
}

.helpInformation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: @primaryText;
}
