@import "../../../../resources/styles/colors";

.nameSummaryContainer {
    display: flex;
}

.nameSummary {
    margin-right: 0.5rem;
}

.icon {
    fill: @secondaryText !important;
}
