@import "../../resources/styles/colors";
@import "../../resources/styles/media";
@import "../../resources/styles/variables";

.rowContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 0.875rem;
    word-wrap: break-word; // To break long usernames or event links if necessary.
    @media (max-width: @screen-md) {
        flex-direction: column;
    }
    .whenContainer {
        flex: 30%;
        min-width: 11rem;
        white-space: nowrap;
        margin-right: 0.5rem;
    }
    .whoContainer {
        min-width: 16rem;
        flex: 20%;
        display: flex;
        flex-direction: row;
        align-items: center; // So the avatar and username line up nicely.
        justify-content: flex-end;
        @media (max-width: @screen-md) {
            justify-content: flex-start;
        }
        margin-right: 0.5rem;
        .usernameText {
            margin: 0 0.25rem;
            max-width: 14rem; // Because we're not using table columns, we needs to accommodate long AD/email usernames first.last@longdomain.com
            line-height: 1rem;
            @media (max-width: @screen-md) {
                width: unset;
            }
        }
    }
    .whatContainer {
        display: flex;
        flex: 50%;
        flex-direction: row;
        align-items: flex-start;
        margin-right: 1rem; // To give us separation from the down-toggle icon in the simple expander.
    }
}

.detailsContainer {
    font-size: 0.875rem;
    padding: 0.25rem 1.25rem;
}

.keyValueGrid {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0;
    .row {
        display: flex;
        flex-direction: row;
        .key {
            flex-basis: 13%;
            text-align: left;
            margin-right: 1.25rem;
            font-weight: @bold;
        }
    }
}

.tooltipContainer {
    text-align: left;
}