@import "../../../resources/styles/colors";

.variableSetLink {
    margin-left: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.header {
    cursor: pointer;
    position:relative;
    height: 100%;
}

.busy {
    position: absolute;
    z-index: 1;
    width: 100%;
}

.textAndButtons {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

.editIcon {
    margin-left: 1rem;
    align-items: center;
    opacity: 0.5;
    color: @secondaryText;
    &:hover {
        opacity: 1;
    }
}
.expandIcon, .expandIcon:hover {
    background-color: transparent!important;
    flex-grow: 0;
    justify-content: flex-end;
}

.expandClosed {
    transform: rotate(0deg)!important;
    flex-grow: 0;
}
.expandOpen {
    transform: rotate(180deg)!important;
    flex-grow: 0;
}