@import "../../../../../resources/styles/media.less";

.filterSection {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.scopeSelectors {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.selectWrap {
    width: 100%;
    display: inline-block;
    @media(min-width: @screen-md) {
        margin-right: 1rem;
        max-width: 250px;
    }
}