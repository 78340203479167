@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;;
}

.lastPublished {
    font-size: 0.75rem;
    text-align: left;
    font-weight: @light;
    color: @ternaryText;
}