@import "../../resources/styles/colors";
@import "../../resources/styles/media.less";

.actionList {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; // Need this to wrap for mobile.
    gap: 0.5rem;
}

.alignStart {
    justify-content: flex-start;
}

.alignEnd {
    justify-content: flex-end;
}

.vNext {
    gap: 1rem;
    align-items: center;
    flex-wrap: unset;
    @media (max-width: @screen-sm) {
        flex-wrap: wrap;
    }
}
