@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.cards-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.add-card-hover {
    background-color: @cardHover;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    .heading, .logo, .content {
        opacity: 0.7;
    }
}

.icon {
    height:2rem;
    line-height: 2rem;
}

