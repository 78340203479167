@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";

.scriptModulesStatus {
    font-size: 14/16rem;
    margin: 0;
}

.includedScriptModules {
    list-style: disc inside;
    color: @primary;
}