@import "../../resources/styles/colors";

.emptyList {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: @secondaryText;
}

.hidden {
    display: none;
}