@import "../../resources/styles/colors.less";
@import "../../resources/styles/media";
@import "../../resources/styles/variables.less";

.container {
    display: flex;
    flex-direction: column;
    width: 300/16rem;
    @media (max-width: @screen-sm) {
        width: 260/16rem;
    }
}

.grow {
    flex-grow: 1;
    padding-right: 10/16rem;
}

.notification {
    padding: 7/16rem 12/16rem;
    display: flex;
}

.title {
    font-size: 14/16rem;
    font-weight: @bold;
    padding-bottom: 1/16rem;
}

.description {
    font-size: 13/16rem;
    color: @ternaryText;
}

.icon {
    display: flex;
    align-items: flex-start;
}

.links {
    margin-top: 10/16rem;
    font-size: 13/16rem;
}

.notificationIcon {
    width: 26/16rem;
    height: 26/16rem;
    line-height: 26/16rem !important;
    text-align: center;
    border-radius: 2/16rem;
    font-size: 14/16rem;
    color: @whiteConstant;
}

.textHappy {
    color: @infoText;
}

.bgHappy {
    background-color: @infoConstant;
}

.textInfo {
    color: @infoText;
}

.bgInfo {
    background-color: @infoConstant;
}

.textWarn {
    color: @alertText;
}

.bgWarn {
    background-color: @alertConstant;
}

.textError {
    color: @dangerText;
}

.bgError {
    background-color: @dangerConstant;
}
