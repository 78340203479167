.headerColumn:nth-child(3) {
  padding-left: 2.95rem;
}

.rowColumn {
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
  padding: 0.175rem 1.125rem!important;
  vertical-align: middle!important;
}

.addRule {
  margin: 1.0rem 0rem;
}