@import "../../../../../resources/styles/colors";

.taskDetailsCell{
  display: flex;
}

.blockIcon{
  opacity: 0.2;
  font-size: 2.5rem;
  color: @primaryText;
}

.cell {
  padding: 0.5rem;
}
