@import "../../../../../resources/styles/colors";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  color: @tenantCount;
  font-size: 0.69rem;
  font-weight: 500;
  //text-shadow: 0 -0.0625rem 0 rgba(0, 0, 0, 0.2);
  padding: 0 0.25rem;
  white-space: nowrap;
  border-radius: 0.25em;
  background: @secondaryText;
}

.progress {
  visibility: visible;
}

.expected {
  visibility: visible;
}