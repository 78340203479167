@import "../../../../resources/styles/colors";

.description {
    font-size: 0.875rem;
    color: @primaryText;
}

.information {
    font-size: 1rem;
    margin: 0.9375rem;
    line-height: 1.5rem;
}

.sidebar {
    font-size: 0.875rem;
}

.callouts {
    li {
        margin-right: 2.5rem;
        margin-left: 2.5rem;
        margin-bottom: 1.5rem;
    }
}

.disabled * {
    color: @secondaryText;

    svg {
        opacity: .5;
    }
}

.markdownContainer {
    padding: 1rem 1rem;
}