.toggleableLegend {
    li {
        cursor: pointer;
        margin-right: 0px !important;
        &:not(:last-child) {
            padding-bottom: 8px;
        }
    }
}

.loading {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}