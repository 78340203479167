@import "../../../resources/styles/colors";

.imageContainer {
    position: relative;
    width: 100%;
    background-color: @whiteConstant;
    justify-content: center;
    align-items: center;
    display: flex;

    .image {
        position: relative;
        width: 100%;
        // Not hardcoding heights in this case due to the dialog needing to shrink/expand slightly at various resolutions.
        padding: 0.5rem;
    }

    .loadingPlaceholder {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}