@import "../../../resources/styles/colors.less";
.menuIcon {
    font-size: 1.37500rem;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    color: @primaryText;
    &:visited {
        color: @primaryText;
    }
    &:hover {
        opacity: 0.5;
    }
}

.container {
    display: flex;
    flex-direction: row;
}

.container> :nth-child(2) {
    margin-left: 0.5rem;
}

.buttons {
    display: flex;
    align-items: center;
}