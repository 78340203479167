@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.inputRow {
    flex: 3;
    padding-right: 2rem;
}

.detailsHighlight {
    padding-top: 1rem;
    background-color: @secondaryBackground;
}

.rightAlignedContainer {
    display: flex;
    flex-direction: row-reverse;
}

.textFormatSelector {
    margin-bottom: -32px;
    width: 10rem;
}

.content{
    display: flex;
    height:100%;
    @media (max-width: @screen-md) {
        display: block;
    }
}

.value {
    flex: 7 1 auto;
    display:flex;
    flex-direction:column;
    >* {
        width:100%;
        flex:1 1 auto;
        display:flex;
        flex-direction:column;
    }
    overflow-y:auto;
}

.editor{
    flex:1 1 auto;
    display:flex;
    flex-direction: column;
}

.editorContainer{
    flex: 1 1 auto;
}