.pageBanners {
    display: flex;
    flex-direction: column;
    background-color: white; // Needed because the callouts that lay within this div are opaque, so if you scroll down they can overlay things badly.
}

.warning {
    width: 100%;
    text-align: center;
    white-space: pre-line;
}