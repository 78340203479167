@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";

.taskSummaryTopLevel {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.taskSummaryChildren {
  margin-left: 1rem;

  .taskSummaryLineText {
    font-weight: normal;
  }
}

.taskSummaryLine{
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  color: @primaryText
}

.taskSummaryLineText {
  cursor: pointer;
  font-weight: bold;
}

.summaryIcon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem!important; // Anything less than 1rem and the rotation animation will distort (on fa-spinner icons).
  text-align: center;
}

.artifactsBlock {
  margin-left: 1.25rem;
}

.highlightBlock {
  color: @infoText;
  margin-left: 1.25rem;
  margin-bottom: 0.5rem;
  background-color: @infoBackground;
  padding: 0.5rem;
  color: @primaryText;
}

.fatalBlock {
  color: @dangerText;
  margin-left: 1.25rem;
}

.retryBlock {
  margin-left: 1.25rem;
  color: @primaryText;
}

.primaryBackground {
  background: @primaryBackground;
}

.editStepButton {
  margin-left: 0.5rem;
}