@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.componentRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end; // So we align the bottom lines of various components consistently, in horizontal containers (like certain filters).
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
}

.componentRow > * {
    margin-right: 1rem;
    @media (max-width: @screen-sm) {
        margin-right: unset;
    }
}