@import "../../resources/styles/colors";

.preWrapped {

    position: relative; // Needed for .changeIndicatorOverlay
    float: left; // Needed for .changeIndicatorOverlay
    width: 100%; // Needed for .changeIndicatorOverlay

    padding: 0.5rem;
    margin: 0 0 0.5rem;
    font-size: 9pt;
    word-break: break-all;
    word-wrap: break-word;
    background-color: @primaryBackground;

    // UX: Give any inserts or deletes some additional underline so they are more discoverable.
    div > ins {
        border-bottom: 0.125rem solid @successBorder !important;
        padding: 0.1rem 0.375rem;
        text-decoration: unset!important;
    }
    div > del {
        border-bottom: 0.125rem solid @dangerBorder !important;
        padding: 0.1rem 0.375rem;
    }
}

.changeIndicatorOverlay {
    margin: 0.5rem 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
}

.changeLine {
    position: absolute;
    min-height: 1.3125rem;
    width: 100%;
    background-color: @auditHighlight;
    border-left: 0.125rem solid @dividerLight;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.changeSet {
    position: relative;
    width: 100%;
    color: @ternaryText;
    del {
        background-color: @dangerBackground !important;
        color: @dangerHeaderText;
    }
    ins {
        background-color: @successBackground!important;
        color: @successHeaderText;
    }
}

.busyIndicator {
    margin: 0 -1.25rem -0.25rem;
}
