@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.container {
    display: flex;
    align-items: flex-end;
}

.nextScheduledRunsTitleContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}