@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.dynamicEnvironmentStateCell {
    display: flex;
    gap: 0.35rem;
    align-items: center;
}

.activeProjectCountCell {
    display: flex;
    justify-content: flex-end;
}

.overflowMenuCell {
    display: flex;
    justify-content: flex-end;
}

.dynamicEnvironmentsHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-left: 1rem;    
    padding-right: 1rem;
}

.dynamicEnvironmentsSortBySelect {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 24rem;
}

.dynamicEnvironmentsSortByLabel {
    min-width: 6rem;
    text-align: right;
    margin-bottom: 0.7rem;
    font-weight: @bold;
    color: @secondaryText;
}

.dynamicEnvironmentsFooter {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
}

.dynamicEnvironmentsTableRowColumnData {
    display: flex;
    align-items: center;
}

.dynamicEnvironmentsTableRowColumn {
    vertical-align: middle;
}

.sortBySelection {
    color: @secondaryText;
}