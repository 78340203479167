@import "../../resources/styles/colors";
@import "../../resources/styles/variables";

.description {
    color: @ternaryText;
    font-size: 0.8125rem;
    font-weight: @light;
    line-height: 1rem;
    p {
        margin: 0.5rem 0; // Override the default
        font-size: 0.8125rem;
        color: @ternaryText;
    }
}