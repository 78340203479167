@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";
@import "../../../../resources/styles/variables";

.formSectionBase() {
    width: 100%;
    box-shadow: none!important; //swap the box-shadow of the material-ui control for a border
}

.formSectionBorder() {
    border-radius: 0!important;
    border-style: solid;
    border-width: 0.0625rem 0 0.0625rem 0;
    border-color: @dividerLight;
}
.formExpander {
    background-color: @paper1 !important;
}
.formExpander, .formNonExpander {
    .formSectionBase();
    .formSectionBorder();
    border-bottom: none;

    // mark.siedle - Oh God, these are hidden away deep in material-ui #plsKillMe
    > div:first-child {
        padding: 0!important;
        > div:first-child {
            padding: 0.75rem 1rem!important;
        }
    }
}

.cardTitleContainer {
    display: flex;
    color: @primaryText;
    align-items: top;
    word-wrap: break-word; // mark.siedle - Needed on settings page for long setting keys to wrap properly.
    @media (max-width: @screen-sm) {
        display:block;
    }
}

.cardTitle {
    top: 1rem;
    width: 15rem !important;
    min-width: 15rem !important;
    min-height: 1.5rem !important;
    font-family: Roboto !important;
    font-size: 1rem !important;
    font-weight: @bold !important;
    line-height: 1.5 !important;
    text-align: right !important;
    color: @primaryText !important;
    @media (max-width: @screen-sm) {
        text-align: left !important;
        position: static !important;
    }
}

.cardSubTitle {
    font-family: Roboto !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    text-align: left !important;
    color: @primaryText !important;
    margin-left: 1.6rem !important;
    margin-right: 2rem !important;
    display: block !important;
    flex-grow: 1;
    word-wrap: break-word;
    min-width: 50%; // ie and firefox require flex children to have a min-width for word-wrap to work https://stackoverflow.com/questions/36150458/flex-item-overflows-container-due-to-long-word-even-after-using-word-wrap
    @media (max-width: @screen-sm) {
        margin-left: 0rem !important;
    }
    > div > p { // our markdown renderer wraps a div then a p around text which has a bunch of top/bottom margin
        margin: 0;
    }
}
.cardSubTitleWithOverflowMenu {
    margin-right: 3rem !important;
}

.overflowMenuActions {
    margin-left: auto;
    align-items: center;
}

.noScoping {
    font-weight: bold;
}

.markdownContainer {
    padding: 1rem 1rem;
}