@import "../../../resources/styles/colors";

.shadow() {
  box-shadow: @shadowSmall;
}

.container {
    margin: auto;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.content {

    .shadow();

    background-color: @paper1;
    width: 360/16rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 0 1.5rem 0;
    }
}
.rememberMe {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.logo {
    color: @whiteConstant;
    font-size: 2rem;
    background-color: @cyanConstant;
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
    h4 {
        margin: 0 0 1rem 0 !important;
    }
    > div:first-child{
        margin: 1rem 0;
    }
}

.authenticationError {
  width:100%;
}

.accountTypeIcon(@icon) {
    background-image: url(@icon);
    background-repeat:no-repeat;
    height: 4rem;
    background-position: center;
    margin-bottom: 2rem;
}

.waitingForOctopus {
  margin: auto;
  width: 22rem;
  position: relative;
  display: block;
  vertical-align: middle;
  text-align: center;

  > div {
    text-align: center;
  }
}


.waitingLogo {
  color: @mainText;
  margin: 0 auto;
  font-size: 4rem;
  width: 60px;
  height: 63px;
  object-fit: contain;
  position: relative;
  bottom: 0;
  z-index: 2;
  margin-bottom:20px;
}

.footerActions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 18.75rem !important;
    height: 6.976875rem !important; // Using height to avoid screen jumping on slow-loading connections.
    margin-top: 2rem;

  }
}