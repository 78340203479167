@import "../../resources/styles/colors";
@import "../../resources/styles/variables";

.textContainer {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.autoCompleteContainer {
    width: 100%;
}

.popover {
    > div {
        overflow-x: hidden;
    }
}

.popoverContent {
    overflow-x: hidden;
}

.popoverInfoText {
    margin: 1rem;
}

.warning {
    margin: 0.5rem 1rem;
    color: @alert;
    font-size: 12/16rem;
}

.buttons {
    position: absolute;
    right: 0;
    top: @componentIconTopMargin;
    cursor: pointer;
}
