@import "../../../resources/styles/variables";
@import "../../../resources/styles/media";
@import "../../../resources/styles/colors";

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.missingIconContainer {
  position: absolute;
}

.missingIconInner {
  position: relative;
  top: -1.5rem;
  right: -1.5rem;
}