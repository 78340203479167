@import "../../../resources/styles/colors";

.icon {
    align-items: center;
    &:hover {
        opacity: 1;
    }
}

.editIcon {
    .icon;
    opacity: 0.5;
    color: @secondaryText;
}

.deletedIcon {
    .icon;
    color: @dangerText;
}

.newIcon {
    .icon;
    color: @successText;
}