.actionsContainer {
    display: flex;
    flex-direction: column;
    text-align: right;
    flex: 0 1 auto;
}

.alignRightContainer {
    display: flex;
    flex-direction: row;
    margin-right: 0.5rem !important;
}

.spacer {
    flex: 1 0 auto;
}