@import "../../../../../resources/styles/variables.less";
@import "../../../../../resources/styles/colors.less";
@import "../../../../../resources/styles/media";

.centered {
    display: flex;
    justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  .centered();
}

.activeCategoryButton {
    background-color: @categoryButtonBackground !important;
}

.cardGroup {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom:100px;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap; // Need this to wrap for mobile.
}

.paperActions{
    padding: 1rem;
}

.actions > * {
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important; // margin at lower resoluations.
}

.link{
    cursor:pointer;
}

.groupHelp {
    margin: 0 1rem;
}