.content {
    padding: 0rem 1rem 2rem;
}

.chartContainer {
    margin-bottom: 2rem;
}

.csvDownloadWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}