@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.container {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    @media (max-width: @screen-sm) {
        flex-wrap: wrap;
    }
    width: 100%;
    column-gap: 0.5rem;
}

.input {
    flex: 1 1 auto;
}

.actions {
    align-self: stretch;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}