@import "../../resources/styles/media";
@import "../../resources/styles/colors";

.container {
    padding: 0.5rem 1rem; // Consistent with .emptyList classes.
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; // Needed for IE11 ><
}

.onboardingImage {
    margin: 1.5rem 0;
    height: 7rem; // Don't use width, use height to avoid screen jumping on slow-loading connections.
}