@import "../../resources/styles/colors";

.container {
  display: flex;
  align-items: center;
}

.thumbprint {
  word-break: break-all;
  color: @primaryText;
}