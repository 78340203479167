.headerColumn:first-child {
  width: 300/16rem!important;
}

.rowColumn {
  word-break: normal;
  white-space: normal;
  padding: 0rem 1.125rem!important;
  vertical-align: middle!important;
}

.rowColumn p {
  margin: 0.5rem 0;
}

.center {
  text-align: center;
}