@import "../../resources/styles/variables";
@import "../../resources/styles/media";
@import "../../resources/styles/colors.less";

.layout
{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.headerContentAlignment {
  padding-left: 1rem;
  padding-right: 1rem;
  color: @primaryText;
}

.title {
  font-size: 1.063rem;
  font-weight: @semibold;
  padding: 0.75rem 1rem;
  color: @primaryText;
}

.titleIcon {
  margin-right: 0.5rem;
  display: inline-block;
}

.body {
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow-y: auto;
  // TODO: Is this a valid solution to scrolling dialogs?
  overflow-x: hidden;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem!important;
  color: @primaryText;
}

.actions {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap; // Need this to wrap for mobile.
  align-items: flex-start; // To keep the expected horizontal alignment between actions.
}

.materialDialogBody {
  padding: 0;
}

.primaryActions {
  display: flex;
  flex-direction: row;
  align-items: flex-start; // To keep the expected horizontal alignment between actions.
  gap: 0.5rem;
}

.secondaryActions {
  display: flex;
  flex-direction: row;
  align-items: flex-start; // To keep the expected horizontal alignment between actions.
  margin-right: auto;
  gap: 0.5rem;
}

.dialogLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto; // Needed for various dialog layouts, play very carefully if you change this.
  .dialogLayoutKeyboardHandler {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  @media (max-width: @screen-sm) {
    display: block;
  }
}

.dialogWrapper {
  height: 100%;
}

.headerContainer {
  padding: 0!important; // Override MUI.
  color: @primaryText;
}

.content {
  padding: 0 10px;
}