@import "../../../../../../resources/styles/media";
@import "../../../../../../resources/styles/colors";
@import "../../../../../../resources/styles/variables";

.deploymentResultItem {

  .deploymentsTableRow {
    letter-spacing: 0px;
    cursor: pointer;
  }

  .deploymentsTableRow a {
    font-weight: @normal;
  }
  .deploymentsTableRow td {
    vertical-align: middle !important;
  }
  .deploymentsTableRow td:last-child {
    padding-bottom: 0.5rem;
  }
  .section {
    font-weight: @normal;
  }
  .healthStatusIconContainer {
    margin-right: 0.25rem;
    border: 0.0625rem solid @logoBackground;
    background: @logoBackground;
    border-radius: 1.875rem;
  }

  .summaryCountContainer{
    display: flex;
  }

  .summaryCount {
    margin: 0.25rem 0;
    padding-right: 0.75rem;
    display: flex;
    align-items: center;
    line-height: 1rem !important;
  }
  .environmentOrTenantIcon {
    display: inline-block;
  }
  .targetColumn {
    display: flex;
    align-items: center;
  }
  .iconContainer {
    display: inline-block;
  }
  .icon {
    display:flex;
    justify-content: center;
    align-items: center;

    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.125rem;
    font-size: 1rem;
    color: @whiteConstant;
  }
  .success {
    color: @successText;
  }
  .error {
    background-color: @danger;
    color: @whiteConstant;
  }
}

.expandCollapse {
  display: flex;
  justify-content: flex-end
}
