.loadMoreContainer {
    padding: 0.25rem 1rem 1rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadMoreActions {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
}
.loadMoreSubText {
    font-size: 0.75rem;
    margin-left: 0.75rem;
}