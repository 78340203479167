@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.statusIcon {
    vertical-align: middle;
    border-radius: 1.875rem;
}

.tileContainer {
    display: grid;
    grid-template-columns: auto 100%;
    grid-template-rows: auto auto auto;
    padding: 0;
    row-gap: 8px;
}

.tileIcon {
    grid-row-start: 1;
    grid-row-end: 4;
    border-radius: 4px;

    margin-right: auto;
    padding: 10px;
    display: flex;
    align-items: center;


}

.logoBackground {
    background-color: rgb(255,255,255);
}

.tileStatusText {
    font-size: 0.9em;
    display: flex;
    align-items: center;
}

.tileHighlightText {
    color: @linkText;
}

.tileStatusIcon {
    margin-right: 5px;
    margin-bottom: 3px;
}

.tileProvider {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    align-items: end;
    margin-bottom: 0;
    margin-top: 6px;
}

.tileStatus {
    grid-row: 2;
    grid-column: 2;
    display: flex;
    align-items: center;
}

.tileStatusSubText {
    grid-row: 3;
    grid-column: 2;
    display: flex;
    align-items: start;
    margin-bottom: 0;
}