@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/media";

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.propertyContainer {
  padding: 0 0.4rem 0 0;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  span:nth-child(2){
    margin-left: 0.25rem;
    margin-right: 0.75rem;
  }
}
