.chartCard {
    border-radius: 0.25rem;
    padding: 36px 12px;
    &Light {
        background: #FAFAFA;
        border: solid 1px #F2F2F2;
    }
    &Dark {
        background: #0F2535;
    }
}