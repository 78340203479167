@import "../../../resources/styles/colors";

.sortableItem {
  border: 0.0625rem solid @dividerLight;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: @secondaryBackground;
  cursor: move;
  color: @primaryText;
}

.name {
  padding-left: 1rem;
}