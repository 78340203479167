
.narrow {
  max-width: 8rem;
}

.summaryMap {
  top: -0.85rem;
}

.project {
  font-size: 1rem;
  display: flex;
  justify-content: left;
  align-items: center;

  div {
    padding-right: .5rem;
  }
}

.phasesEditHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addPhaseActionContainer {
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
}