.controlCell {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}


.lastColumn {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.scopeCell {
    flex-grow: 1;
    flex-shrink: 1;
}

.fullSizeCell {
    height: 100%;
    width: 100%;
}
