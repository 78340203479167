@import "../../../../resources/styles/colors";

.header {
    display: flex;
    margin: 1rem;
    vertical-align: middle;
}

.title {
    flex-grow: 1;
    font-size: 1.0625rem;;
    font-weight: 600;
}

.filter {
    width: 16rem;
}

.sort {
    display: flex;
    width: 20rem;
    align-items: center;
}

.sortLabel {
    margin-left: 2rem;
    margin-right: 1rem;
    font-weight: 700;
}

.table {
    :global(.ReactVirtualized__Table__headerColumn):first-of-type,
    :global(.ReactVirtualized__Table__rowColumn):first-of-type {
        margin-left: 1rem;
    }

    :global(.ReactVirtualized__Table__headerRow) {
        text-transform: none;
    }

    :global(.ReactVirtualized__Table__rowColumn) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: visible !important;
    }

    :global(.ReactVirtualized__Table__row) {
        border-bottom: dashed 0.0625rem @dividerLight;
    }

    :global(.ReactVirtualized__Table__row):last-child {
        border-bottom: none;
    }
}

.dynamicDashboardTaskStatusDetailContainer {
    margin-left: -0.5rem;
}

.overflowMenuColumn {
    justify-content: flex-end !important;
}

.runbookSnapshotName {
    max-width: 15rem;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.deploymentReleaseVersion {
    display: inline;
    max-width: 15rem;
    text-overflow: ellipsis;
    overflow-x: hidden;    
}

.environmentName {
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.footer {
    margin: 1rem;
}

