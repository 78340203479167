@import "../../resources/styles/variables";

.filterField {
    position: relative;
    display: inline-block;
    .searchIcon {
        position: absolute;
        right: 0;
        top: @componentIconTopMargin;
        width: 1.5rem;
        height: 1.5rem;
    }

    /* clears the 'X' from Internet Explorer */
    input[type="search"]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
    input[type="search"]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    /* clears the padding and overlay issues on Safari */
    input[type="search"] {
        -webkit-appearance: none;
    }
}

.filterFieldFullWidth {
    width: 100%;
}

.filterTextBoxStandardWidth {
    width: 15.6rem !important; /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */
}

.filterTextBoxFullWidth {
    width: 100% !important;
}
