@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";
@import "../../../resources/styles/variables";

.tagSetContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.connectButtonContainer {
  width: 100%;
  text-align: center;
}

.linkedProject{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: @semibold;
  flex-grow: 1;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0!important; // Override the padding happening from our SimpleList.

    .projectName {
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        margin-left: 0.25rem;
        font-size: 1.063rem;
        font-weight: 500;
      }
    }
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0!important; // Override the padding happening from our SimpleList.
}

.noEnvironmentsWarning{
  color: @alert;
  padding-top: 0.5rem;
}

.noEnvironmentsWarningIcon{
  padding-right: 0.5rem;
}

.noProjectAccess {
  margin-top: 1rem;
}