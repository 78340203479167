@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  min-width: 11.25rem;
  padding: 0.5rem;
  .additionalDetails {
    margin-left: 0.5rem;
  }
}

.task-previous {
  opacity:.65;
}

.task-archived {
  opacity: .2;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0.5rem;
  width: 100%;
}

.version, .taskName {
  color: @primaryText;
  font-weight: @semibold;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.date {
  color: @ternaryText;
  font-weight: @normal;
  font-size: 0.6875rem;
}

.taskLink {
  display: inherit;
  &:hover {
    background-color: @hover;
  }
}

.statusIcon {
  align-self: normal;
}