@import "../../../../../resources/styles/media";

.content {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.copy {
    max-width: calc(100% - 150px);
}

.button {
    @media (min-width: @drawerBreakpointWidth) {
        display: flex;
        align-items: center;
        position: absolute;
        top: -20px;
        right: 4px;
    }
}
