@import "../../resources/styles/colors.less";

.logo {
    display: flex;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    background-color: @logoBackground;

    & img {
        padding: 0.25rem;
        border-radius: 60/16rem;
    }

    .logo-image-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    img {
        display: block;
        object-fit: scale-down;
    }
    .disabled {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        /* filter doesn't work for IE11, known issue, we fallback to opacity only in this case */
        opacity: 0.4;
    }
}
