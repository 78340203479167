@import "../../../../resources/styles/media";
@import "../../../../resources/styles/colors";

.runbooksContainer {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}

.eapContainer {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    .eapBadge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 1rem;
        background: @infoBackground;
        color: @infoText;
        font-size: 0.7rem;
        border-radius: 1rem;
    }
}

.branchSelector {
    margin-top: 1rem;
}

.icon, .noIconIndented {
    width: 20px;
    margin-right: 0.25rem;
}

.noIcon {
    // This space has been intentionally left blank
}

.vcsErrorPanelContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}
