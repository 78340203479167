@import "../../resources/styles/colors";

@animation-duration : 5s;
@size : 0.875rem;
@border: 0.0625rem;
@gap: 0.0625rem;
@spinner-color : @whiteConstant;
@icon-color: @info;

@bordersize: @size - (2 * @gap);
@spinnersize: @bordersize - (2 * @border) - (2 * @gap);
@spinnerradius: @spinnersize /2;


* { box-sizing:border-box; }

.outer {
  display: inline-block;
  background: @icon-color;
  width: @size;
  height: @size;
  padding: @gap;
  vertical-align: top;
  margin-right: 8/16rem;
  margin-top: 1/16rem;
}

.border {
  background: @icon-color;
  width: @bordersize;
  height: @bordersize;
  border: solid @border @spinner-color;
  border-radius: 50%;
  padding: @gap;
}

.wrapper {
  width:@spinnersize;
  height:@spinnersize;
  position:relative;
  background:@icon-color;
  border-radius: 50%;
}

.pie {
  width: 50%;
  height: 100%;
  transform-origin: 100% 50%;
  position: absolute;
  background: @spinner-color;
}

.spinner {
  border-radius: @spinnerradius 0 0 @spinnerradius;
  z-index: 200;
  border-right:none;
  animation: rota @animation-duration linear infinite;
}

.filler1 {
  border-radius: 0 @spinnerradius @spinnerradius 0;
  left: 50%;
  opacity: 0;
  z-index: 100;
  animation: fill1 @animation-duration steps(1,end) infinite;
  border-left: none;
}

.filler2 {
  border-radius: @spinnerradius 0 0 @spinnerradius;
  opacity: 1;
  z-index: 100;
  animation: fill2 @animation-duration steps(1,end) infinite;
  border-left: none;
}

.mask1 {
  border-radius: @spinnerradius 0 0 @spinnerradius;
  width: 50%;
  height: 100%;
  position: absolute;
  background: inherit;
  opacity: 1;
  z-index: 300;
  animation: mask1 @animation-duration steps(1,end) infinite;
}

.mask2 {
  border-radius: 0 @spinnerradius @spinnerradius 0;
  width: 50%;
  height: 100%;
  left: 50%;
  position: absolute;
  background: inherit;
  opacity: 1;
  z-index: 300;
  animation: mask2 @animation-duration steps(1,end) infinite;
}

@keyframes rota {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(360deg); }
  50.01% { transform: rotate(180deg); }
  100% { transform: rotate(540deg); }
}

@keyframes mask1 {
  0% { opacity: 1; }
  25%, 100% { opacity: 0; }
}

@keyframes mask2 {
  0% { opacity: 0; }
  75%, 100% { opacity: 1; }
}

@keyframes fill1 {
  0% { opacity: 0; }
  25%, 50% { opacity: 1; }
  50.01%, 100% { opacity: 0; }
}

@keyframes fill2 {
  0% { opacity: 0; }
  50%, 75% { opacity: 1; }
  75.01%, 100% { opacity: 0; }
}