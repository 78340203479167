@import "../../../../resources/styles/media";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.wrapper {
    overflow-x: auto;
}

.container {
    height: 56px;
    background: @statusBarBackground;
    font-size: 14px;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: start;
    padding-left: @margin-double;
    padding-right: @margin-double;
    user-select: none;
    min-width: 880px;
}

.title {
    font-weight: 700;
    white-space: nowrap;
}

.statusItemContainer {
    padding: 8px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: @margin-single;
    border-radius: 4px;
    color: @primaryText;
    white-space: nowrap;
    
    &.active {
        padding: 8px 12px 8px 10px;
        background: @statusBarItemBackground;
        cursor: pointer;
        font-weight: 500;

        &:hover {
            color: @primaryText;
        }
    }
}

.statusItemText {
    margin-top: -2px;
}
