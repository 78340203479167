@import "../../resources/styles/variables";

.links {
    display: flex;
    font-weight: 700;
    align-items: center;
    gap: 20px;
}

.video {
    width: 100%;
    border-radius: @radiusMd;
}