@import "../../resources/styles/colors";

.listItem {
    padding: 1.25rem;
    font-size: 0.875rem !important;
    // mark.siedle - Oh God, these are hidden away deep in material-ui #plsKillMe
    > div:first-child {
        padding: 0!important;
        > div:first-child {
            padding: 0.75rem 1rem!important;
        }
    }
}

.emptyList {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: @secondaryText;
}

.pagingControlContainer {
    margin: 1.5rem 1rem;
}

.disablePagingEvents {
    pointer-events: none;
}

.loadMoreContainer {
    padding: 1rem;
}
.loadMoreActions {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
}
.loadMoreSubText {
    font-size: 0.75rem;
    color: @secondaryText;
}