
.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inlineSearch {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.claimContent {
  display: block;
}