@import "../../../../../resources/styles/colors.less";
@import "../../../../../resources/styles/media.less";

.content {
    padding: 0rem 1rem 2rem;
}

.twoColumnGrid {
    min-width: 0;
    display: grid;
    gap: 3rem;
    grid-template-columns: 100%;
    @media (min-width: 1500px) {
        grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);
    }
}

.tempoGrid {
    margin-bottom: 3rem;
}

.groupHeading {
    margin-bottom: 0px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    display: inline-block;
    color: inherit; // Inherit parent link colour.
}