@import "../../resources/styles/variables";
@import "../../resources/styles/colors";

.error {
    padding: 1.5rem 1rem 1.5rem 1rem;
    color: @dangerHeaderText;
    background-color: @dangerBackground;
    ul {
        padding-left: 1rem;
        margin-bottom: 0;
        list-style-type: disc;
        font-weight: @normal;
    }
    text-align: left;

    .note {
        margin-top: 1rem;
    }
}

.titleContainer {
    display: flex;
    justify-content: space-between;
}

.title {
    font-weight: @semibold;
}

.detailLink {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}