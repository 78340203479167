@import "../../../resources/styles/colors";

.scopeCell {
    height: 100%;
}

.defineScope {
    cursor: inherit;
    overflow: hidden;
    color: @secondaryText;
}
