@import "../../resources/styles/colors";

.emptyList {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: @secondaryText;
}

.headerBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.hidden{
    display: none;
}