@import "../../resources/styles/colors";
@import "../../resources/styles/fonts";
@import "../../resources/styles/media";
@import "../../resources/styles/variables";

.logLine {
  @media (max-width: @screen-sm) { /* mark.siedle - Flex hackery to get this working for mobile */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    > div {
      width: 100% !important;
    }
  }
}

.topLevelBlock {
  margin: 1rem;
}

.messages {
  padding: 1rem;
}

.message {
  margin-right: 18rem;
  @media (max-width: @screen-sm) {
    margin-right: unset;
  }
  white-space: pre-wrap;
  word-break: break-word; /* mark.siedle - Needed or this can push out containers and stop sidebars from wrapping properly. */
  min-width: 10rem; /* mark.siedle - Needed or log lines can compress text to the extreme :) */
}

.category {
  width: 5rem;
  float: right;
}

.occurred {
  float: right;
  width: auto;
}

.gap {
  font-style: italic;
}

.element {
}

.elementError {
  .element;
  color: @dangerText;
}

.elementWarning {
  .element;
  color: @alertText;
  font-weight: @semibold;
}

.elementHighlight {
  .element;
  color: @infoText;
  font-weight: @bold;
}

.elementWait {
  .element;
  color: @infoText;
}

.elementVerbose {
  .element;
  color: @secondaryText;
}

.elementInfo {
  .element;
}

.elementRetry {
  display: none;
}