.defaultIconButton {
    width: 2.25rem !important; // Overriding the material-ui default to match our typical button height.
    height: 2.25rem !important;
    padding: 0.5rem !important;
}

.copyValue {
    display: flex;
    align-items: center;
}
