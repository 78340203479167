@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.parentStepIcon {
  background-color: @parentStepBackground;
  border-radius: 5rem;
}

.nameSummaryContainer {
  display: flex;
}

.nameSummary {
  margin-right: 0.5rem;
}

.slugSummary {
  margin-right: 0.5rem;
  color: @secondaryText;
}