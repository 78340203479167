@import "../../../../../resources/styles/colors";

.minimumEnvText {
  max-width: none !important;
  width: 2.5rem !important;
  display: inline-block !important;
  z-index: 10;
  height: 1.25rem !important;
  margin-bottom: 0.2rem !important;

  hr {
    bottom: 0 !important;
  }

  input {
    font-size: 0.875rem !important;
    color: @primaryText !important;

    &:disabled {
      color: @secondaryText !important;
    }
  }
}

.minCompleteLabel {
  margin-left: 0 !important;
  height: 1.25rem;
}