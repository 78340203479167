.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkbox {
    flex-grow: 1;
}

.checkboxIcon {
    flex-grow: 0;
}