@import "../../../../../resources/styles/colors.less";

.textColor {
    color: @whiteConstant;
}

.label {
    display: block;
    margin-bottom: 8px;
}

.list {
    margin: 0;
}

.listItem {
    display: flex;
    align-items: center;
    white-space: pre;
    &:not(:last-child) {
        margin-bottom: 4px;
    }
}

.colorIndicator {
    display: inline-block;
    height: 2px;
    width: 15px;
    margin-right: 10px;
}