@import "colors.less";

code {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    color: @codeText;
    background-color: @codeHighlight;
    border-radius: 4/16rem;
    padding: 2/16rem 4/16rem;
    line-height: normal;
}

pre {
    background-color: @codeHighlight;
    color: @codeText;
    padding: 0.4rem;
    margin: 0 0 0.4rem;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
}
