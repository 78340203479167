@import "../../../resources/styles/colors";

.scopesContainer {
    align-items: center;
    display: inline-flex;
    height: 100%;
    width: 100%;
}

.clickable {
    cursor: pointer;
}

.scopeCell {
    height: 100%;
}

.clickContainer {
    height: 100%;
}

.chipContainer {
    display: inline-flex;
    align-items: center;
}

.hidden {
    visibility: hidden;
}

.allChipsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.focusManagedWrapper {
    height: 100%;
}

.defineScope {
    cursor: inherit;
    overflow: hidden;
    color: @secondaryText;
}