@import "../../../resources/styles/colors";

.icon {
    align-items: center;
    &:hover {
        opacity: 1;
    }
}

.controlCell {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.lastColumn {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.scopeCell {
    flex-grow: 1;
    flex-shrink: 1;
}

.editIcon {
    .icon;
    opacity: 0.5;
    color: @secondaryText;
}

.newIcon {
    .icon;
    color: @successText;
}

.variablePlaceholderText {
    height: 100%;
    align-items: flex-end;
    display: flex;
    color: @secondaryText;
    font-size: 0.75rem;
    padding: 0.25rem 0;
}
