@import "../../../../../resources/styles/colors";

.environmentList {
  padding: 0!important;
}

.phaseDeleteButton {
  margin-right: 1.4rem!important;
  margin-top: 0.5rem!important;
}

.sectionControl {
  margin-top: 1rem;
}

.anyEnvironment {
  margin-top: 1rem;
  display: block;
  color: @primaryText;
}

.padUnder {
  margin-bottom: 0.25rem;
}

.missingResource {
  color: @dangerText;
}

.strongText {
  font-weight: strong;
}