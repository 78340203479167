@import "../../../resources/styles/variables";
@import "../../../resources/styles/colors";

.titleBar{
    background-color: @paper1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index:1;
    flex: 0 0 auto;
    padding: 1rem;
  }

.title {
    flex-grow: 1;
    font-size: 1.063rem;
    font-weight: 500;
    h1, h2 {
      font-size: 1.25rem;
      margin: 0;
      padding: 0;
    }
  }

.actions {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap; // Need this to wrap for mobile.
  gap: 0.5rem;
}
