.content {
    padding: 0 24px 16px;
}

.buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
}
