@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/fonts";
@import "../../../../../resources/styles/media";

.cardHeaderActions {
  right: 3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
}


.taskActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: @screen-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.filter {
  width: 12rem;
  margin-right: 1rem;
}

.filterActionContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap; // Need this to wrap for mobile.
}