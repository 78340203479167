@import "../../../../resources/styles/colors";

.machineIcon {
  width: 2.438rem;
  height: 2.563rem; // Always set height or the screen gets janky as images load.
}

.machineImage {
  margin-right: 0.5rem;
  background-color: @logoBackground;
  border-radius: 3.125rem;
  padding: 0.5rem;
  width: 3.125rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.machineImageSmall {
  width: 1rem;
  height: 1rem;

  &>.machineIcon {
    width: 1.25rem;
    height: 1.25rem;
  }
}