@import "../../../resources/styles/colors.less";

.valueCell {
    height: 100%;
}

.deletedText {
    color: @secondaryText !important;
    text-decoration: line-through
}

.deletedSensitive {
    color: @secondaryText !important;
}

.valueCellContentReadonly {
    height: 100%;
    display: flex;
    align-items: center;
}

.promptedVariablePositionContainer {
    padding-right: 1rem;
}

.promptedVariableIconSizeContainer {
    height: 1.5rem;
    width: 1.5rem;
}

.promptedVariableIcon {
    opacity: 0.5;
    color: @secondaryText;
}

.gitSensitiveVariablePositionContainer {
    padding-right: 1rem;
}

.gitSensitiveVariableIconSizeContainer {
    height: 1.1rem;
    width: 1.5rem;
}

.gitSensitiveVariableIcon:global(.MuiSvgIcon-root) {
    opacity: 0.5;
    color: @secondaryText;

    height: 1.1rem;
    width: 1.5rem;

    margin-left: auto;
    margin-right: auto;
}

.value {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconContainer {
    height: inherit;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}

.certificate, .account, .workerPool {
    height: inherit;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:focus {
        outline: none;
    }
}

.certificateLink, .accountLink, .workerPoolLink {
    height: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: @primaryDark;
}

.handCursor {
    cursor: pointer;
}

.multilineText {
    padding-top:  14px;
    max-height: 35px;
    padding-bottom: 10px;
    overflow: hidden !important;
}

.multilineTextFocused {
    padding-top:14px !important;
    max-height:300px !important;

}

.multilineContainer {
    white-space: pre;
    width: 100%;
    height: 100%;
}