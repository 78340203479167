@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.summary {
    margin: 0.25rem 0;
    padding-right: 0.4rem;
    margin-left:1rem !important;
    display: flex;
    align-items: center;
    font-size: 0.8125rem !important; // Done on purpose to match design, this has to match for both the action button and the close button.
    text-transform: uppercase; // To match ternary action so this toggles seamlessly.
    font-weight: 500;

    > svg {
        margin-right: 0.5rem;
    }
}
