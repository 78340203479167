.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    > .wide {
        flex-grow: 1;
    }
    > .buttons {
        display: flex;
        align-items: center;
    }
}

.container> :nth-child(2) {
    margin-left: 0.5rem;
}