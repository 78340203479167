@import "../../../../../../resources/styles/media";

.envAndTenantSelectorContainer {
  .envAndTenantSummary {
    align-items: center;
  }
  @media (min-width: @screen-sm) {
    .deployToAllTenantsButton {
      top: -3.0rem;
      left: 11rem;
    }
  }
  .tenantIntersection {
    padding-left: 2.5rem;
  }

  .channelCallout {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.noTenantsExplanation {
  text-align: center;
}
