@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  min-width: 11.25rem;
  padding: 0.5rem;
  display: inline-flex;
  .additionalDetails {
    margin-left: 0.5rem;
  }
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0.5rem;
  width: 100%;
}

.snapshotName, .snapshotNotes {
  color: @ternaryText;
  font-weight: @normal;
  font-size: 0.6875rem;
}
.snapshotNotes {
  margin: 0.5rem 0;
  padding: 0.5rem;
}

.date {
  color: @primaryText;
  font-weight: @semibold;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.taskLink {
  display: inherit;
  &:hover {
    background-color: @hover;
  }
}
