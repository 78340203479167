.content {
    padding: 8px 24px 16px;
}

.title {
    margin-bottom: 6px;
    font-weight: 500;
}

.version {
    opacity: 0.8;
}

.description {
    margin-top: 16px;
    margin-bottom: 12px;
    opacity: 0.8;
}

.buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 8px;
}
