@import "../../resources/styles/colors";
@import "../../resources/styles/variables";
@import "../../resources/styles/media";

.components {
    margin-top: 0;

  li {
    margin-top: 0.5rem;
  }
}

.entry {
  margin-top: 1rem;
  margin-bottom: 1rem;
  ul.bulletList
  {
    margin-left: 3rem;

    li {
      list-style: disc;
    }
  }
  .content {
    color: @primaryText;
    font-size: 14/16rem;
  }
  .styleGuideExample {
    margin-bottom: 0.5rem;
  }
}

.container {
  margin: 1rem 2rem;
  display: flex;
  flex-direction: row;
  .componentMenu {
    min-width: 18.75rem;
  }
  > ul {
    list-style: none;
    > li {
      margin-bottom: 5px;
    }
  }

  @media (max-width: @screen-sm) {
    flex-direction: column;
  }
}

.heading {
  text-align: left;
}

.content {
  margin: 2rem 1rem;
}

.context {
  display: block;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: @secondaryBackground;
  font-size: 14/16rem;
  color: @ternaryText;
   p {
     font-size: 14/16rem;
     color: @ternaryText;
   }
}

thead {
  font-weight: @bold;
}
.colors {
  display: flex;
  flex-direction: column;
  .colorSwatch {
    flex-direction: row;
    display: flex;
    align-items: center;
    border-top: 0.0625rem solid @dividerLight;
    height: 2.5rem;
      &:last-child {
        border-bottom: 0.0625rem solid @dividerLight;
      }
      .colorName {
        font-weight: 500;
        margin: 0 1rem 0 1rem;
        width: 30%;
      }
      .colorValue {
        color: @secondaryText;
        &:before {
          content: "HEX";
          background-color: @secondaryBackground;
          margin-right:0.25rem;
          font-size: 0.75rem;
          padding:0.25rem;
        }
      }
  }
}
.swatch {
  width: 3rem;
  height: 2.5rem;
}
.white {
  background: @whiteConstant;
}

.secondaryTextColor {
  color: @secondaryText;
}

.primaryText {
  background: @primaryText;
}

.secondaryText {
  background: @secondaryText;
}

.primary {
  background: @mainBackground;
}

.primaryLight {
  background: @primaryLight;
}

.primaryDark{
  background: @primaryDark;
}

.primaryBackgroundDark{
  background: @primaryBackground;
}

.readonlyTextWithStrikethrough {
  > input {
    text-decoration: line-through;
    color: @secondaryText !important;
  }
}

.sidebarWithColor {
  background-color: grey;
}
.row {
  display: flex;
  flex-direction: row;
}

.buttons tr td {
  padding: 0.5rem 0;
}

.card {
  padding-bottom: 0.5rem;
}

.cardTitle {
  font-weight: 500;
  padding: 1rem;
}

.filterFieldContainer {
  display: flex;
  flex-direction: row;
  .filterField {
    margin: 0 0.5rem;
    width: 100%;
    &>div{
        width: 100%;
    }
  }
}

.secondaryColor {
  color: @secondaryText
}


//TaskLog
@import "../../components/TaskLogLines/taskLogBlock.module";
