@import "../../../resources/styles/colors";

.internalLink {
  color: @linkText;
  text-decoration: none;

  &:hover {
    color: @linkTextHover
  }
}

.listInternalLink {
  color: @primaryText;
  &:hover, &:focus {
    color: @primaryText;
  }
}