@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.lastTaskSection {
    margin: 1.5rem 0;
}

.actionButtonWrap {
    margin-bottom: 1rem;
    p {
        margin-bottom: 0.25rem;
    }
}

.taskTime {
    margin-right:0.5rem;
}