@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/colors";

.checkboxGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.checkbox {
    position: relative;
    overflow: visible;
    display: table;
    height: auto;
    min-width: 6.875rem;
}

.horizontal > div {
    width: auto!important;
    margin-right: 3rem;
}

.disabled * {
    color: @disabledButtonText;

    svg {
        opacity: .5;
    }
}

.markdownContainer {
    padding: 1rem 1rem;
}