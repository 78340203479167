@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.shadow() {
  box-shadow: @shadowSmall;
}

.container {
    margin: auto;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.content {
    .shadow();
    background-color: @paper1;
    border-radius: 0.25rem;
    overflow: hidden; // To support border-radius and our horizontal loading indicator.
    width: 360/16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
        color: @whiteConstant;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 0 1.5rem 0;
      min-width: 18rem;
    }
}

.formActionButton {
  margin-top: 1rem;
}

.rememberMe {
  margin-top: 1.5rem;
}

.logo {
    color: @whiteConstant;
    font-size:2rem;
    background-color: @cyanConstant;
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
    h4 {
        margin: 0 0 1rem 0 !important;
    }
    > div:first-child{
        margin: 1rem 0;
    }
}

.externalAuthProvider {
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  width: 18rem;
  margin-left: auto;
  margin-right: auto;
  & * {
      font-weight: 400 !important;
  }
}

.externalNonFormsProviders {
   background: @secondaryBackground;
   padding: 1rem 0;
   width:100%;
}

.guestProvider {
    text-align: center;
    margin: 1rem auto;
    cursor: pointer;
    color: @mainText;
}

.authenticationError {
  width:100%;
}

.accountTypeSelection {
    .shadow();
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap; // Needed for mobile.
    align-items: center; // Needed for mobile.
    justify-content: center; // Needed for mobile.
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
}

.accountType() {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    width: 19rem;
    height: 19rem;
    padding-top: 5.5rem;
    text-align: center;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.accountTypeIcon {
    height: 4rem;
    margin-bottom: 2rem;
}

.guestAccount {
    background-color: @whiteConstant;
    .accountType();
    h2 {
        color: @cyanConstant;
    }
}

.authenticatedAccount {
    background-color: @cyanConstant;
    .accountType();
    h2 {
        color: @whiteConstant;
    }
}

.areas > div {
    opacity: 1;
    transition: all 0.5s linear;
}

.areas:hover > .guestAccount:hover, .areas:hover > .authenticatedAccount:hover {
    opacity: 1;
    transition: all 0.5s linear;
    .shadow();
}

.areas:hover > .guestAccount:not(:hover), .areas:hover > .authenticatedAccount:not(:hover) {
    opacity: 0.5;
    transition: all 0.5s linear;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img, svg {
    width: 18.75rem !important;
    height: 6.976875rem !important; // Using height to avoid screen jumping on slow-loading connections.
    margin-top: 2rem;
  }
}