@import "../../../resources/styles/colors";

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.separator {
  margin: 0 0.425rem 0 0.125rem;
  color: @primaryText;
}

.error {
  color: @dangerText;
  font-size: 0.75rem;
  margin-top: 0.125rem;
}
.input {
 + b, ~ b {
    background-color: @pagingButtonBackground !important;
    border-style: none !important;
    &:hover {
      background-color: @pagingButtonBackgroundHover !important;
      > i, ~i {
        background-color: @whiteConstant !important;
      }
    }
    > i, ~ i {
      background-color: @primaryText !important;
    }
  }
}