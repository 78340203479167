.content{
    flex:auto;
    overflow-y:auto;
}

.block {
    height:100%;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.flex {
    display:flex;
    flex-direction: column;

    >* {
        flex:auto;
    }
}

.padded {
    padding-left: 1rem;
    padding-right: 1rem;
}