@import "../../../resources/styles/colors.less";

.nameCellContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.spacer {
    flex-grow: 1;
}

.value {
    display: flex;
}

.promptedVariablePositionContainer {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
}

.promptedVariableIconSizeContainer {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
}
.promptedVariableIcon {
    opacity: 0.5;
    color: @secondaryText;
}