@import "../../../resources/styles/colors";

.container{
  display:flex;
  width: 7rem;
  position: relative;
  flex: 0 0 7rem; //ie11
}

.colorSwatch {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25em;
  border: 1px solid @dividerLight;
  position: absolute;
  bottom: 1.5rem;
  right: 0.5rem;
  cursor: pointer;
}

.colorPickItem {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  transition: background-color 0.1s linear;
  border-radius: 0;
}

.colorAllOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}