@import "../../../../resources/styles/text";
@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";

.packageVersionCell {
  vertical-align: middle !important;
  &:nth-child(2){
    font-weight: @semibold;

  }
}

.row {
  display: flex;
}

.actionButtonGroup {
  margin-bottom: 1rem;
}
.center {
  text-align: center;
}