@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.headerLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: @screen-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.assignmentAction {
  margin-left: 0.5rem;
}

.notes {
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
.formElements {
  background-color: @paper0;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}