@import "../../../../../resources/styles/colors";
.ruleIconWrap {
    display: flex;
    justify-content: center;
    em {
        line-height: 20/16rem;
    }
}

.validRule {
    color: @successText;
}

.validRuleIcon {
    .validRule;
    .ruleIconWrap;
}

.invalidRule {
    color: @dangerText;
    text-decoration: line-through;
}

.invalidRuleIcon {
    color: @dangerText;
    .ruleIconWrap;
}

.badRuleIcon {
    color: @alertText;
    .ruleIconWrap;
}

.badRuleIcon {
    .badRule;
    .ruleIconWrap;
}

.badRule {
    color: @alertText;
}

.codeGutter {
    width: 22/16rem;
}

.tooltip {
    background-color: @ternaryText;
    border-color: @ternaryText transparent transparent transparent;
    border-width: 0.3125rem;
    border-style: solid;
    border-radius: 0.25rem;
    color: @whiteConstant;
    font-family: monospace;
    font-size: 10pt;
    overflow: hidden;
    padding: 0.125rem 0.3125rem;
    position: fixed;
    white-space: pre;
    white-space: pre-wrap;
    z-index: 2000;
    max-width: 600px;
    opacity: 0;
    transition: opacity .4s;
}