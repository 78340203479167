
.commitCommentContainer {
    display: flex;
    margin-bottom: 0.3rem;
}

.commitLink {
    &:after {
        content: "-";
        margin: 0 0.5rem;
    }
}

.commitComment {
    white-space: pre-wrap;
}
