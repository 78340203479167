@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.rowHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: left;
  font-weight: @semibold;
}

.blockAlert, .variableAlert{
  font-size: 1rem;
  margin-left: 0.5rem;
}

.blockAlertIcon{
  font-size: 1rem;
  color: @dangerText;
}

.rowCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.disabled * {
  color: @secondaryText;
}

.busyContainer {
  margin-left: 0.625rem;
}