@import "../../../resources/styles/media";
@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";

.centre {
    text-align:center;
}

.content {
    font-size: 0.875rem;
    color: @ternaryText;
    margin: 1rem 9rem .5rem 7rem;
    min-height: 3.75rem; // UX - to provide a consistent min-height for content (in the 80% case) ... to avoid our buttons from jumping around as you click next/next/next.
}

.heading {
    font-size: 1.5rem;
    color: @primaryText;
    font-weight: 400;
    margin-top: 1rem;
}

.image {
    width: auto;
    padding: 0 !important;
    height: 26.5rem; // Set height for slow-loading situations to avoid images jumping while loading.
    @media (max-width: @screen-md) {
        width: 100%;
        height: auto;
    }
}

.container {
    position: relative;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    & * {
       fill: @iconNavy !important;
    }
}