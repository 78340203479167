@import "../../resources/styles/colors.less";

.hoverlessContainer {
  &:hover {
    // We need to override the default box-shadow hover that occurs in Card.
    box-shadow: @shadowSmall !important;
  }

}

.content {
  margin-top: 1rem;
}