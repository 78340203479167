@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.stepName {
  font-weight: 500;
  padding-left: 0.4rem;
}

.stepNameContainer {
  line-height: 1.4rem;
  font-size: 1rem;
}

.lastUpdated {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.expandoBodyContainer {
  padding-left: 1rem;
  padding-right: 1rem;
}

.kindContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-left: 0.0625rem solid var(--dividerLight);
  border-right: 0.0625rem solid var(--dividerLight);
}

.sectionHeadingTitle{
  font-size: 14/16rem;
  font-weight: 700;
}

.paperLayoutOverride {
  > div:first-child {
    max-width: unset;
    box-shadow: unset;
    border-top: 0.0625rem solid @dividerLight;
    border-bottom: 0.0625rem solid @dividerLight;
    margin: 0;
    border-radius: unset;
  }
}

.leftRightContainer {
  display: flex;
  justify-content: space-between;
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    gap: 10/16rem;
    width: 100%;
  }
  > div:first-child {
    display: flex;
    justify-content: flex-start;
    gap: 10/16rem;
    width: 100%;
  }
}

.filterContainer {
  padding: 0.75rem 1rem;
}

.selectWrap {
  width: 100%;
  max-width: 250/16rem;
}

.callout {
  margin-top: 10/16rem;
  margin-bottom: 10/16rem;
}

.feedbackContainer {
  margin: 10/16rem;
}