@import "../../../../resources/styles/variables";

.filterBox {
  margin-left: 1rem;
  margin-top: 2.6rem;
}

.filter {
  width: auto;
  align-items: flex-end;
}

.row {
  display: flex;
  flex-direction: row;
}

.avatar {
  vertical-align: middle;
  justify-content: center;
  padding-right: 0.5rem;
}

.linkWithCopy {
  display: inline-flex;
  flex-direction: row;
  .copyAction {
    margin: -1rem 0;
  }
  .link {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}