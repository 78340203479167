@import "../../../resources/styles/colors.less";

.headerContainer {
    width: 100%
}

.headerRowContainer {
    display: flex;
    flex-direction: row;
}

.virtualList {
    &:focus {
        outline: none; // overrides default browser styles that makes the whole table act focus-able
    }

    overflow: visible !important;
    > * {
        // This is !important in order to override element styles in the
        // react-virtualized list element that cannot be changed any other way
        overflow: visible !important;
    }
}

.table {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tableBody {
    flex: 1 0 auto;
    // I'm not quite sure why, but this prevents any popovers from appearing over other elements (like the floating table headers)
    // This is a copy of the style from react-virtualized that we need when we aren't virtualizing the list
    will-change: transform;
}