.cellWrapper {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto; // Columns shouldn't not adjust size, instead they should use the exact % width provided to them
}

.cellContentWrapper {
    flex-grow: 1;
    min-width: 0; // https://stackoverflow.com/questions/31069474/text-overflow-in-combination-with-flexbox-not-working
    flex-basis: 0; // Required to get the above min-width: 0 working in IE11
}

.alignedCellsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}