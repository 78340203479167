@import "../../../../resources/styles/media";

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: visible;
    width: 375px;
    @media (max-width: @screen-sm) {
        width: 100%;
        grid-auto-rows: auto;
    }
}