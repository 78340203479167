@import "../../../resources/styles/colors.less";
.menuIcon {
    font-size: 1.37500rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: @primaryText;
    &:visited {
        color: @primaryText;
    }
    &:hover {
        opacity: 0.5;
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 500/16rem;
}

.menuContainer {
    overflow-y: auto;
    height: 400/16rem;
}

.filter {
    margin: 0 1rem;
}

.prompt {
    margin: 0rem 1rem;
    padding: 1rem 0rem;
    border-top: 1px solid @statusBarBackground;
}